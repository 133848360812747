import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

class AnnouncementBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      announcements: []
    };
  }

  componentDidMount() {
    // Fetch all the announcement messages
    axios.get("/announcements.json").then((res) => this.setState({ announcements: res.data }));
  }

  setBackgroundColor(level) {
    switch (level) {
      case "Notification":
        return "has-background-light has-text-black";
      case "Warning":
        return "has-background-warning has-text-black";
      case "Danger":
        return "has-background-danger has-text-white";
      default:
        return "is-light";
    }
  }

  render() {
    return (
      <div id="announcement-banner-component">
        {this.state.announcements.map((announcement) => (
          <div
            key={announcement.content}
            className={`announcement-item ${this.setBackgroundColor(announcement.level)}`}
          >
            <span id="announcement-icon" className="fa fa-bullhorn"></span>
            {announcement.content}
          </div>
        ))}
      </div>
    );
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("annonucement-banner");

  if (element) {
    ReactDOM.render(<AnnouncementBanner cssClass={element.className} />, element);
  }
});
